/* src/App.css */

.App {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.server-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
}

.server-status p {
  margin: 0;
}

.up {
  color: green;
}

.down {
  color: red;
}
